
@font-face {
  font-family: "Font";
  src: url("./Fonts/HankenGrotesk-Regular.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: "Font-Medium";
  src: url("./Fonts/HankenGrotesk-Medium.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: "Font-Bold";
  src: url("./Fonts/HankenGrotesk-Bold.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: "Font-Semi";
  src: url("./Fonts/HankenGrotesk-SemiBold.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: "Font-Light";
  src: url("./Fonts/HankenGrotesk-Light.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
}

body,div,p,h1,h2,h3,span {
  font-family: 'Font', 'Montserrat', sans-serif;
}

.menubg {
  --tw-bg-opacity: 0.3;
  background-color: rgba(249, 250, 251, var(--tw-bg-opacity));
}

.al-i-c{
  align-items: center;
}
circle {
  transition: stroke-dashoffset 0.35s;
  transform: rotate(-90deg);
  transform-origin: 50% 50%;
}
.cairo{
  font-family: 'cairo';
}

.auth{
    position: relative;
}

.auth::before{
    content: "";
    mix-blend-mode: multiply;
    background: #C734FF;
    position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: 0;
}

.lift{
  transform: translateY(-90px);
}

.form-control {
  position: relative;
  margin: 20px 0;
}

.form-control input {
  border: 0;
  border-bottom: 2px solid rgba(32, 27, 55, 0.2);
  padding: 10px 8px 6px 10px;
  display: block;
  font-size: 16px;
  color: #001526;
  font-family: 'Font';
  width: 100%;
  transition: 0.25s ease-in;
}

.form-control input:disabled {
  background: #f9f9f9;
}

.display-none{
  display: none;
}

.form-control input:focus{
  border-bottom-color: #201B37;
  outline: none;
}

.form-control span{
  position: absolute;
  top: 14px;
  right: 15px;
}

.form-control label span {
  display: inline-block;
  font-size: 18px;
  min-width: 5px;
  transition: 0.3s cubic-bezier(0.68, -0.55, 0.265, 1.55);
}

.form-control label {
  position: absolute;
  top: 15px;
  left: 0;
  color: #647C8F;
}

input:focus ~ label,input:not(:focus):valid ~ label{
  font-size: 12px;
  transform: translateY(-180%);
  transition: transform 0.18s  ease-in-out;
}

.form-control.disabled label {
  color: rgba(100, 124, 143, 0.6);
  transform: translateY(-180%);
  font-size: 12px;
}

li:hover{
  background: #D7D7F5;
  border-radius: 3px;
  cursor: pointer;
}

.work{
  font-family: 'Work Sans', sans-serif;
}

/* progress ring styling */
.rings{
  height: 80px;
}

.percent1 svg, .percent1 circle {
  width: 80px;
  height: 80px;
}

.rings circle {
  position: absolute;
  fill: none;
  stroke-width: 10;
  transform: translate(10px, 10px);
  stroke-dasharray: 440;
  stroke-linecap: round;
}

.rings circle:nth-child(1) {
  stroke-dashoffset: 0;
  stroke: #424242;
}

.percent1{
  position: relative;
}

.percent1 circle:nth-child(2) {
  stroke-dashoffset: calc(440 - (440 * 29) / 100);
  stroke: #1AB00D;
  animation: percent 1.5s linear;
  animation-delay: 1s;
}

.number {
  position: absolute;
  bottom: 35%;
  left: 25px;
  color: #03045E;
}

h2 {
  font-size: 15px;
}

.rings span {
  font-size: 24px;
  opacity: .7;
}

.filter {
  width: 106px;
  margin-left: -30px;
  margin-top: 10px;
  border-radius: 4px; }
  .filter li {
    font-family: Inter;
    padding: 10px 16px;
    color: #201B37;
    font-size: 14px;
    line-height: 21px;
    letter-spacing: -0.01em; }
    .filter li:hover {
      color: #000000;
      background: #EAEAEA; }

.card{
  background-color: #FFFFFF;
  border-radius: 10px;
  box-shadow: 0px 4px 30px rgba(0, 0, 0, 0.02);
}

.checkbox{
  width: 30px;
  height: 30px;
  margin-right: 20px;
}
.react-datepicker__input-container input{
    width: 100%;
    height: 40px;
    background: #FFFFFF;
    border: 0.75px solid #A0A4A8;
    border-radius: 6px;
    padding: 13.5px 18px;
}

.tbl-prdct{
  min-width: 370px;
  margin-bottom: 20px;
}
.tbl-prdct2{
  min-width: 270px;
}

@keyframes percent {
  0% {
    stroke-dashoffset: 0;
    stroke-width: 0;
  }
}
.rotate-90-cw {
	-webkit-animation: rotate-90-cw 0.2s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
	        animation: rotate-90-cw 0.2s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
}
.rotate-90-ccw {
	-webkit-animation: rotate-90-ccw 0.4s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
	        animation: rotate-90-ccw 0.4s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
}
@-webkit-keyframes rotate-90-cw {
  0% {
    -webkit-transform: rotate(0);
            transform: rotate(0);
  }
  100% {
    -webkit-transform: rotate(90deg);
            transform: rotate(90deg);
  }
}
@keyframes rotate-90-cw {
  0% {
    -webkit-transform: rotate(0);
            transform: rotate(0);
  }
  100% {
    -webkit-transform: rotate(90deg);
            transform: rotate(90deg);
  }
}
@-webkit-keyframes rotate-90-ccw {
  0% {
    -webkit-transform: rotate(0);
            transform: rotate(0);
  }
  100% {
    -webkit-transform: rotate(-2deg);
            transform: rotate(-2deg);
  }
}
@keyframes rotate-90-ccw {
  0% {
    -webkit-transform: rotate(0);
            transform: rotate(0);
  }
  100% {
    -webkit-transform: rotate(-2deg);
            transform: rotate(-2deg);
  }
}

.mookie-icon-wrapper {
  opacity: 0;
  transition: opacity 0.3s ease-in-out;
}

.mookie-icon-wrapper.fade-in {
  opacity: 1;
}

.mookie-icon-wrapper.fade-out {
  opacity: 0;
}

.inputContainer {
  width: 100%;
  display: flex;
  flex-direction: row;
  gap: 10px;
  align-items: center;
  justify-content: center;
}

.otp-input {
  background-color: rgb(228, 228, 228);
  width: 30px;
  height: 30px;
  text-align: center;
  border: none;
  border-radius: 7px;
  caret-color: rgb(127, 129, 255);
  color: rgb(44, 44, 44);
  outline: none;
  font-weight: 600;
}

.otp-input:focus,
.otp-input:valid {
  background-color: rgba(127, 129, 255, 0.199);
  transition-duration: .3s;
}

.custom-link {
  color: #5E5E5E;
  font-style: normal;
  font-weight: 600;
  line-height: 33px;
  cursor: pointer; /* Default cursor style */
}

.custom-link:hover {
  color: #C734E5; /* Change color on hover */
}

.custom-link:disabled {
  color: #B0B0B0; /* Lighter color for disabled state */
  cursor: not-allowed; /* Change cursor to indicate it's disabled */
  opacity: 0.6; /* Optional: make the button look less prominent */
}

/* Optional: Add hover style for disabled buttons (if desired) */
.custom-link:disabled:hover {
  color: #B0B0B0; /* Keep the same color on hover for disabled */
}
